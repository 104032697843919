@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

.tab-button {
  font-size: 18px;
  font-weight: 400;
  min-width: 120px;
  padding: 12px 8px;
  border-radius: 8px;
  border: 1px solid darkgray;
  background-color: white;
  margin: 0 5px;
}

.tab-button:hover {
  background-color: #C0DDFF;
}

.tab-button:focus,
.tab-button:active,
.tab-button.selected {
  background-color: #98C6FF;
  font-weight: 600;
}

.logout-button {
  padding: 8px 20px;
  border: 1px solid gray;
  border-radius: 8px;
  position: absolute;
  right: 20px;
}

.logout-button:hover {
  background-color: #98C6FF;
}

/* Auth form */
.auth-form {
  border-radius: 12px;
  width: 65%;
  margin: 0 auto;
  padding: 7vh 10vw;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.auth-form h1 {
  text-align: left;
  font-size: 34px;
  font-weight: 500;
  line-height: 51px;
}

.auth-para {
  margin: 10px 0 20px;
  line-height: 25px;
  font-size: 16px;
  font-weight: 400;
}

.form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 45%;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  background-color: rgb(245, 243, 243);
}

.form-first-input {
  margin-right: 20px;
}

.auth-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: black;
  color: #FFDD00;
  cursor: pointer;
  width: 100%;
}

.auth-or-button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: white;
  color: black;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 821px) {
  .auth-form {
    width: 90%;
  }

  .form-group {
    flex: 1 1 100%;
    min-width: 100%;
  }

  .form-row {
    margin-bottom: 0px;
  }
}

/* Plans CSS */
.plan-form {
  border-radius: 12px;
  width: 65%;
  margin: 0 auto;
  padding: 7vh 10vw;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #FFDD00;
}

.plan-form h1 {
  text-align: left;
  font-size: 34px;
  font-weight: 500;
  line-height: 51px;
}

.plan-para {
  margin: 10px 0 20px;
  line-height: 25px;
  font-size: 16px;
  font-weight: 400;
}

.plan-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: black;
  color: #FFDD00;
  cursor: pointer;
  width: 100%;
}

.plan-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 45%;
}

.plan-form-group label {
  margin-bottom: 5px;
}

.plan-form-group input {
  background-color: #FFDD00;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid black;
}

@media (max-width: 950px) {
  .plan-form {
    width: 90%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-text {
  flex-grow: 1;
}

/* Navigation Card */
.navigation-cards-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.navigation-card {
  background: #FFDD00;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.3s;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-card:hover {
  transform: scale(1.05);
}

.navigation-card p {
  margin: 10px 0 0;
  font-size: 18px;
}

.navigation-card-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #FFDD00;
  color: black;
  border: 1px solid black;
  border-radius: 200px;
  font-size: 16px;
  cursor: pointer;
}

.navigation-card-button:hover {
  background-color: black;
  color: #FFDD00;
}

@media (max-width: 720px) {
  .navigation-card {
    flex: 1 1 100%;
  }
}

.suggested {
  margin-top: 20px;
}

.suggested-amounts {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.suggested-amount-button {
  padding: 5px 10px;
  background: #FFDD00;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.suggested-amount-button:hover {
  background-color: black;
  color: #FFDD00;
}
