/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Global Styles */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* Header Styles */
header {
  height: 12vh;
  position: relative;
  background: #fd0;
  margin-bottom: 40px;
}

/* Top Navigation */
header .top-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 18px 0;
}

header .top-nav .logo-sec img {
  width: 128px;
  height: auto;
  object-fit: cover;
}

header .top-nav .menu-sec .navbar-nav {
  list-style: none;
  padding: 0;
}

header .top-nav .menu-sec .navbar-nav .nav-item .nav-link {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}

header .top-nav .menu-sec .navbar-nav .nav-item .nav-link.active,
header .top-nav .menu-sec .navbar-nav .nav-item .nav-link:hover {
  font-weight: 600;
}

header .top-nav .contact-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

header .top-nav .contact-sec .icon {
  display: flex;
  align-items: center;
}

header .top-nav .contact-sec .icon a {
  text-decoration: none;
  color: #000;
  height: 36px;
  width: 140px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 500;
}

header .top-nav .contact-sec .icon a:hover {
  background: #fff;
}

/* Navbar toggler and collapsible menu */
.navbar-toggler {
  background: #fff;
  height: 25px;
  width: 35px;
}

.navbar-toggler.collapsed:after {
  content: "\f0c9"; /* FontAwesome hamburger icon */
}

.navbar-toggler:not(.collapsed):after {
  content: "\f00d"; /* FontAwesome close icon */
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

/* Banner Down Section */
.banner-down {
  padding: 60px 0;
}

/* Media Section */
.media-sec .media {
  align-items: center;
  border: 3px solid #000;
  border-radius: 10px;
  height: 130px;
  width: 100%;
}

.media-sec .media.media1 img {
  padding-left: 20px;
}

.media-sec .media img {
  padding-left: 10px;
}

.media-sec .media .media-body p {
  margin: 0;
  font-size: 14px;
  width: 98%;
  color: #000;
  font-weight: 400;
}

/* Card Section */
.card-sec {
  padding: 60px 0;
}

.card-sec .heading {
  text-align: center;
}

.card-sec .card {
  border: none;
  text-align: center;
  background: #fd0;
}

.card-sec .card img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin: 0 auto;
  margin-top: 20px;
}

.card-sec .card .card-body .btn {
  border: 1px solid #000;
  height: 36px;
  width: 140px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
}

.card-sec .card .card-body .btn:hover {
  background: #000;
  color: #fd0;
}

/* Save Time Section */
.save-time-sec .img-box img {
  height: 400px;
  width: auto;
  object-fit: contain;
}

.save-time-sec ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.save-time-sec ul li {
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

/* Text Card Section */
.text-card-sec {
  padding-bottom: 60px;
  padding-top: 4%;
}

.text-card-sec .inner {
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
}

.text-card-sec .inner .card {
  border: none;
}

.text-card-sec .inner .card .card-body .heading {
  font-size: 35px;
}

.text-card-sec .inner .card .card-body .card-subtitle {
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
}

.text-card-sec .inner .card .card-body .card-text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

/* Step Section */
.step-sec {
  text-align: center;
  background: #fd0;
  width: 100%;
  height: auto;
}

.step-sec .heading {
  padding-top: 30px;
}

.step-sec .card {
  background: none;
  border: none;
}

.step-sec .card .card-body .card-subtitle {
  padding-top: 10px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
}

/* Price Section */
.price-sec {
  text-align: center;
  padding: 60px 0;
}

.price-sec .card {
  margin-top: 40px;
  transition: all .5s ease;
  border: 1px solid #000;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.price-sec .card:hover {
  transform: scale(1.2);
  z-index: 1;
  background: #fd0;
  border: none;
}

.price-sec .card .card-body {
  padding: 30px 0;
}

.price-sec .card .card-body .card-title {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.price-sec .card .card-body .card-text {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  width: 70%;
  margin: 20px auto;
}

.price-sec .card .card-body .price {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  padding: 10px 0 20px;
}

.price-sec .card .card-body .btn {
  height: 40px;
  width: 140px;
  border: 1px solid #000;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  text-decoration: none;
  box-shadow: none;
  outline: none;
}

/* Footer Styles */
footer {
  background: #fd0;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
}

footer .container {
  margin: 40px auto;
}

footer .logo-sec {
  padding: 55px 0 0;
}

footer .logo-sec img {
  height: auto;
  max-width: 100%;
}

footer .logo-sec .tag-line {
  padding: 10px 0 0;
}

footer .logo-sec .tag-line a {
  color: #000;
  font-size: 14px;
  text-decoration: none;
  box-shadow: none;
  outline: none;
}

footer .logo-sec .icon {
  display: flex;
  padding: 15px 0;
}

footer .logo-sec .icon a {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: 0 20px 0 0;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}

footer .inner-text {
  padding: 30px 60px;
}

footer .inner-text .head {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 40px 0 20px;
}

footer .inner-text ul {
  list-style: none;
  padding: 0;
}

footer .inner-text ul a {
  text-decoration: none;
  box-shadow: none;
  outline: none;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
}

footer .inner-text ul a:hover {
  font-weight: 500;
}

/* General Heading Styles */
.heading {
  font-size: 40px;
  color: #000;
  font-weight: 500;
  padding-bottom: 20px;
}

.heading span {
  color: #fd0;
  font-weight: 600;
}

.sub-heading {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 15px;
}

.copyright {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid #000;
  padding: 20px 0px;
}

@media(max-width: 991px) {
  /* Header styles */
  body header {
    position: relative;
  }

  /* Top Navigation */
  .top-nav {
    position: relative;
  }

  /* Contact Section */
  .top-nav .contact-sec {
    position: absolute;
    top: -61px;
    left: 110px;
    display: none;
  }

  .top-nav .contact-sec .icon {
    display: flex;
  }

  .top-nav .contact-sec .icon .number {
    padding-left: 10px;
  }

  .top-nav .contact-sec .icon ul {
    column-gap: 10px;
    -moz-column-gap: 10px;
  }

  .top-nav .contact-sec .icon ul a {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }

  .top-nav .logo-sec img {
    height: auto;
    width: 80px;
    object-fit: contain;
  }

  /* Menu Section */
  .top-nav .menu-sec .navbar {
    position: relative;
    z-index: 999;
  }

  .top-nav .menu-sec .navbar .navbar-toggler {
    position: absolute;
    top: -35px;
    right: 0;
    background: #fff;
    height: 30px;
    width: 35px;
  }

  .top-nav .menu-sec .navbar .navbar-toggler:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 8px;
    font-family: "FontAwesome";
  }

  .navbar-toggler-icon {
    position: absolute;
    left: 4px;
    top: 1px;
    width: 25px !important;
    height: 25px !important;
  }

  .top-nav .menu-sec .navbar .navbar-toggler.collapsed:after {
    content: "";
  }

  .top-nav .menu-sec .navbar .navbar-collapse {
    position: absolute;
    top: 200%;
    background: #fff;
    width: 110%;
    left: -15px;
    padding: 20px;
    right: 10px;
  }

  .top-nav .menu-sec .navbar-nav {
    padding: 0 0 40px;
  }

  .top-nav .menu-sec .navbar-nav .show {
    display: block;
    position: absolute;
    bottom: 0;
    top: 77%;
  }

  /* Banner Section */
  .banner-sec .img-box img {
    height: auto;
    width: 100%;
  }

  /* Media Section */
  .media-sec .media {
    margin: 10px 0;
  }

  /* Card Section */
  .card-sec .card,
  .text-card-sec .inner,
  .price-sec .card {
    margin: 10px 0;
  }

  .price-sec .card {
    margin-top: 20px;
  }

  /* Footer */
  footer .inner-text {
    padding: 0;
  }
}